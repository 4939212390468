import {
  favoriteCurrenciesQuery,
  transferRateHistoryQuery,
  favoriteExchangeRatesQuery,
  transferRates,
  transferRatesMulticurrencyAvailable,
  saveCustomerFavoriteRateMutation,
  saveCustomerFavoriteExchangeRateMutation,
  removeCustomerFavoriteRateMutation,
  removeCustomerFavoriteExchangeRateMutation,
  updateRatesNotificationSettingsMutation,
  getRatesNotificationSettingsQuery,
  saveCustomerOneTimeRateNotificationMutation,
  saveCustomerOneTimeExchangeRateNotificationMutation,
  transferParametersQuery,
  paymentOptions,
  calcMoneyTransferAmountOnlineV2Query
} from './graphql.graphql';
import axios from 'axios';
import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';

export const getFavoriteCurrencies = async (business_unique_id) => await apiMainQuery(favoriteCurrenciesQuery, { business_unique_id });

export const getFavoriteExchangeRates = async (business_unique_id) => await apiMainQuery(favoriteExchangeRatesQuery, { business_unique_id });


export const getTransferParameters = async (country_id, ben_payment_id, is_instant_speed) =>
  await apiMainQuery(transferParametersQuery, {
    country_id,
    ben_payment_id,
    is_instant_speed
  })

export const getTransferRates = async (params) => await apiMainQuery(transferRates, params);

export const getTransferRatesMulticurrency = async () => await apiMainQuery(transferRatesMulticurrencyAvailable);

export const getTransferRateHistory = async params =>
  await apiMainQuery(transferRateHistoryQuery, params);

export const getExchangeCurrencyRateHistory = async ({ currency, dateFrom, dateTo }) => {
  var config = {
    method: 'get',
    url: `${process.env.REACT_APP_API}/public-services/online-currency-exchange-rate-history?country_to=${currency}&date_from=${dateFrom}&date_to=${dateTo}`,
    headers: {
      'Cookie': 'next-i18next=en'
    }
  };
  return await axios(config);
}

export const getPaymentOptions = async params => await apiMainQuery(paymentOptions, params);

export const saveCustomerFavoriteRate = async params =>
  await apiMainMutation(saveCustomerFavoriteRateMutation, params);

export const saveCustomerFavoriteExchangeRate = async params =>
  await apiMainMutation(saveCustomerFavoriteExchangeRateMutation, params);

export const removeCustomerFavoriteRate = async params =>
  await apiMainMutation(removeCustomerFavoriteRateMutation, params);


export const removeCustomerFavoriteExchangeRate = async params =>
  await apiMainMutation(removeCustomerFavoriteExchangeRateMutation, params);

export const getRatesNotificationSettings = async () =>
  await apiMainQuery(getRatesNotificationSettingsQuery);
export const updateRatesNotificationSettings = async params =>
  await apiMainMutation(updateRatesNotificationSettingsMutation, params);

export const saveCustomerOneTimeRateNotification = async params =>
  await apiMainMutation(saveCustomerOneTimeRateNotificationMutation, params);

export const saveCustomerOneTimeExchangeRateNotification = async params =>
  await apiMainMutation(saveCustomerOneTimeExchangeRateNotificationMutation, params);

  export const getTransferRatesOnline = async ({ currency, dateFrom, dateTo }) => {
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/public-services/online-currency-exchange-rate-history?country_to=${currency}&date_from=${dateFrom}&date_to=${dateTo}`,
      headers: {
        'Cookie': 'next-i18next=en'
      }
    };
    return await axios(config);
  }

export const calcMoneyTransferAmountOnlineV2 = async ({ transfer_amount, special_rate_type, business_unique_id, is_promo_rate, country_id, receiving_amount, is_instant_rate, currency_code, beneficiary_payment_info_id, is_existing_user, is_special_rate }) =>
  await apiMainQuery(calcMoneyTransferAmountOnlineV2Query, {
    transfer_amount,
    special_rate_type,
    business_unique_id, 
    is_promo_rate,
    country_id,
    receiving_amount,
    is_instant_rate,
    is_special_rate,
    currency_code,
    beneficiary_payment_info_id,
    is_existing_user
  })